.section {
  max-width: 1200px; /* Adjust width as needed */
  text-align: center; /* Aligns content to the center */
  margin: auto;
}

#Home{
  margin-top: 20px;
  margin-bottom: 10px;
}

.section-title {
  margin-top: 50px;
  margin-bottom: 40px; /* Spacing between title and description */
  font-size: 2.5em; /* Adjust font size as needed */
}

.section-title-home{
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 2.5em; /* Adjust font size as needed */
}

.date-title{
  margin-top: 20px;
  margin-bottom: 60px;
  font-size: 2em;
}

.section-description {
  font-size: 1.2em; /* Adjust font size as needed */
  margin: auto; /* Centers the text block */
}

@media screen and (max-width: 800px) {

  .section {
    margin: 0 15px !important; /* 0 top, 50px elsewhere */
    max-width: 1200px; /* Adjust width as needed */
    text-align: center; /* Aligns content to the center */

  }

  #Home{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .section-title {
    font-size: 2.3em; /* Adjust font size as needed */
    margin-top: 30px;
    margin-bottom: 30px; /* Spacing between title and description */
  }

  .section-title-home {
    font-size: 2.5em; /* Adjust font size as needed */
    margin-top: 15px;
  }
  
  .date-title{
    font-size: 1.8em;
    margin-bottom: 30px;
  }
  
  .section-description {
    font-size: 1.2em; /* Adjust font size as needed */
    margin: auto; /* Centers the text block */
  }
}