
  .language-modal .modal-header .modal-title {
    flex: 1; /* Allow the title to take up the full width of the header */
    text-align: center; /* Ensure text is centered within the title space */
  }
  
  .language-modal .modal-body {
    padding: 1.5rem; /* Reduced padding around buttons */
  }
  
  .language-button {
    font-weight: bold; /* Make the text a bit bolder */
  }
  
  .modal-body{
    margin-top: 0px;
  }
  
  /* Additional styling for the buttons on hover */
  .language-button:hover {
    transform: scale(1.05); /* Slightly increase the button size */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  }
  