.navbar {
    width: 100%;
    z-index: 4;
    /* Increase the height of the navbar */
    height: 80px; /* or any other value you prefer */
    background-color: white !important; /* Replace with the color you want */
    font-size: 0.9em;
}

.navbar-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
  }
  
.navbar-logo img {
    height: 90px; /* Or the height you desire */
    width: auto; /* Maintain aspect ratio */
}

.navbar-collapse {
    width: 94%; /* Adjust the percentage to increase/decrease the width of the menu */
    padding-left: 3%; /* Half of the total horizontal space (left space) */
    padding-right: 3%; /* Half of the total horizontal space (right space) */
    background-color: white !important; /* Same as navbar's background color */
}

/* Adjusting the padding to center the content vertically */
.navbar .container-fluid {
    height: 100%;
    display: flex;
    align-items: center;
}

/* Ensuring the content doesn't hide behind the fixed navbar */
body {
    padding-top: 80px; /* Adjust based on the height of your navbar */
}

@media screen and (max-width: 1000px) {

    .navbar .navbar-brand {
        margin-left: 20px; /* Adjust as needed */
    }

    .navbar-toggler {
        margin-left: 20px;
    }

    .navbar-collapse {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 60%; /* Adjust the width percentage as needed */
        padding-left: 10%; /* Creates space on the left */
        padding-right: 10%; /* Creates space on the right */
        top: 100%;
        box-sizing: border-box; /* Include padding and borders in the width and height */
        background-color: #c8e0fa;
        transition: transform 0.3s ease, opacity 0.3s ease;
        display: none;
        opacity: 0;
        margin-left: auto; /* Centers the element */
        margin-right: auto; /* Centers the element */
    }

    .navbar-collapse.collapsing {
        display: block; /* Show the menu */
        opacity: 0; /* Menu is transparent during transition */
    }

    .navbar-collapse.show {
        display: block; /* Ensure the menu is displayed */
        opacity: 1; /* Menu is opaque when shown */
        transform: translateX(-50%); /* Ensure the menu remains centered */
    }

    .nav-link {
        text-align: center; /* Center align the nav-links */
        padding: .5rem 1rem; /* Adjust padding as needed */
        border-bottom: 0.3px solid #ddd; /* Add a border to the bottom of each link */
    }

    .navbar-collapse .nav-link:not(:last-child) {
        border-bottom: 0.1px solid black; /* Adjust color and style as needed */
    }

  }


