.section-separator {
  border-top: 1px solid #ccc; /* Gray line style */
  margin-top: 10px; /* Adjust margin as needed */
  margin-bottom: 10px; /* Adjust margin as needed */
  width: 90%; /* Width of the separator line */
  margin-left: 5%; /* 10% margin on the left */
  margin-right: 5%; /* 10% margin on the right */
}

.app-container {
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}

.section-title, .section-title-home {
  font-family: "Playfair Display SC", serif;
  font-weight: 400;
  font-style: italic;
}

.section {
  /* This value should be around the height of the top navbar*/
  scroll-margin-top: 140px;
}

.colored-section{
  background-color: rgb(247, 252, 255);
}

.button{
  background-color: rgb(80, 141, 168) !important;
  border: 2px solid   rgb(80, 141, 168) !important; /* The existing border color */
}


@media screen and (max-width: 600px) { /* Adjust max-width as needed for your target phone sizes */
  body {
    font-size: 12px; /* Smaller font size for phones */
  }

  /* Add other specific styles for phone screens here */
  /* Example: Adjusting padding and margin for smaller screens */
  .section-separator {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .section {
    scroll-margin-top: 130px; /* Smaller top margin */
  }
}