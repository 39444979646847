.image-with-countdown{
    height: 100%;
}

.header-main-image {
    width: 100%;
    height: 100vh; /* Full viewport height */
    display: block; /* Ensures no extra space around the image */
    height: auto; /* Adjust if necessary */
    object-fit: cover;
    /* Other styles */
}

.container-main-image {
    width: 100%; /* Adjust as necessary */
    padding: 0; /* Adjust as necessary */
    margin: 0 auto; /* Center the container */
    background-color: #FFFFFF; /* Replace with the color you want */
}

.overlapping-countdown {
    position: relative;
    bottom: 120px;
    height: 0;
}



@media screen and (max-width: 800px) {

    .header-main-image {
        width: 100%;
        height: 100vh; /* Full viewport height */
        display: block; /* Ensures no extra space around the image */
        height: auto; /* Adjust if necessary */
        /* Other styles */
    }
}

@media screen and (min-width: 1000px) {
    .header-main-image {
        margin-top: -20px !important;
        width: 80%;
        margin: auto;
    }
}

@media screen and (max-width: 700px) {
    .overlapping-countdown {
        bottom: 110px;
    }
}

@media screen and (max-width: 600px) {
    .overlapping-countdown {
        bottom: 80px;
    }
}

@media screen and (max-width: 400px) {
    .overlapping-countdown {
        bottom: 70px;
    }
}
